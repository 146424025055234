<template>
  <div>
    <el-row>
      <el-button
        v-throttle
        size="medium"
        @click="
          () => {
            this.$router.back();
          }
        "
        >返回</el-button
      >
      <el-button
        v-throttle
        size="medium"
        type="danger"
        @click="handleBundleBtnClick(false)"
      >
        批量取消加入
      </el-button>
      <el-button
        v-throttle
        size="medium"
        type="primary"
        @click="handleBundleBtnClick(true)"
      >
        批量加入试评卷
      </el-button>
      <div style="text-align: right; margin-top: -20px">
        <el-form inline>
          <el-form-item class="formItemBoxStyle" label="考试题目">
            <el-select v-model="operationTestId" @change="fetchTestPaperList">
              <el-option label="全部" :value="0"></el-option>
              <el-option
                v-for="scoreQuestion in scoreQuestionList"
                :key="scoreQuestion.operationTestId"
                :value="scoreQuestion.operationTestId"
                :label="scoreQuestion.operationTestName"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </el-row>
    <el-row>
      <el-table
        header-align="center"
        :header-cell-style="{
          'text-align': 'center',
          background: '#DFE6EC',
        }"
        :data="testPaperData"
        ref="testPaperData"
        :cell-style="{ 'text-align': 'center' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column
          label="考生"
          width="200"
          prop="examineeName"
        ></el-table-column>
        <el-table-column
          label="考题"
          prop="operationTestName"
        ></el-table-column>
        <el-table-column
          label="评分次数"
          width="200"
          prop="markCount"
        ></el-table-column>
        <el-table-column
          label="平均分"
          width="200"
          prop="avgScore"
        ></el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <span
              v-if="scope.row.enabledExamMarkRecordTest"
              @click="fetchTestPaperMarkStatus(false, [scope.row.examineeId])"
              style="
                color: #f40;
                text-decoration: underline;
                cursor: pointer;
                margin-right: 5px;
              "
              >取消加入</span
            >
            <span
              v-else
              style="
                color: rgb(24, 144, 255);
                text-decoration: underline;
                cursor: pointer;
                margin-right: 5px;
              "
              @click="fetchTestPaperMarkStatus(true, [scope.row.examineeId])"
              >加入试评卷</span
            >
            <span style="color: #ccc">|</span>
            <span
              @click="handleCheckBtnClick(scope.row, scope.$index)"
              style="
                color: rgb(24, 144, 255);
                text-decoration: underline;
                cursor: pointer;
                margin-left: 5px;
              "
              >查看试卷</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin: 20px 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>
  </div>
</template>

<script>
import {
  getTestPaperListApi,
  updateTestPaperStatusApi,
} from "@/api/marking/markingTest";
export default {
  data() {
    return {
      testPaperData: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      examId: "",
      operationTestId: 0,
      scoreQuestionList: [],
      selection: [],
      totalNum: 0
    };
  },
  created() {
    const { examId, scoreQuestionList } = this.$route.query;
    this.examId = +examId;
    this.scoreQuestionList = JSON.parse(scoreQuestionList);

    this.fetchTestPaperList();
  },
  methods: {
    // 获取一份试卷
    fetchTestPaperList() {
      getTestPaperListApi(
        this.page.pageIndex,
        this.page.pageSize,
        this.examId,
        this.operationTestId
      ).then((res) => {
        if (res.success) {
          this.testPaperData = res.data;
          this.page.totalSize = res.total;
          !this.totalNum && (this.totalNum = res.total)
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 修改阅卷状态
    fetchTestPaperMarkStatus(enabledExamMarkRecordTest, data) {
      updateTestPaperStatusApi(this.examId, enabledExamMarkRecordTest, data).then((res) => {
        if (res.success) {
          this.$message.success("修改成功");
          this.fetchTestPaperList();
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取选中数据
    handleSelectionChange(selection) {
      this.selection = selection;
    },
    // 批量操作
    handleBundleBtnClick(status) {
      if(this.selection.length == 0) {
        this.$message.error("未勾选试卷")
        return
      }
      let data = this.selection
        .filter((it) => it.enabledExamMarkRecordTest !== status)
        .map((it) => it.examineeId);
      this.fetchTestPaperMarkStatus(status, data);
    },
    // 查看视频
    handleCheckBtnClick(row) {
      // console.log(index + 1);
      this.$router.push({
        path: `/markingVideo/markingVideo`,
        query: {
          examId: this.examId,
          operationTestId: row.operationTestId,
          totalNum: this.totalNum,
          currentData: JSON.stringify(row)
        },
      });
    },
    handleSizeChange(val) {
      this.page.pageIndex = 1;
      this.page.pageSize = val;
      this.fetchTestPaperList();
    },
    handleCurrentChange(val) {
      this.page.pageIndex = val;
      this.fetchTestPaperList();
    },
  },
};
</script>

<style></style>
